import React, { useEffect, useState } from 'react';
import { Iconify, Page } from '@components';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import Scrollbar from '@components/shared/Scrollbar';
import { UserListHead } from 'src/components/pages/user';
import { env } from '@env';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

function PopularNews() {
  const [voiceNews, setVoiceNews] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getVoiceNews = async (page = 0) => {
    await axios
      .get(`${env.API_URL}/admin/getVoiceNews?page=${page + 1}`)
      .then((res) => {
        setVoiceNews(res.data);
      });
  };

  const handleChangePage = async (event, newPage) => {
    await getVoiceNews(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    await getVoiceNews(0);
    setPage(0);
  };

  useEffect(() => {
    getVoiceNews();
  }, []);

  return (
    <Page title="Voice News">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Voice News
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'name', label: 'Name', alignRight: false },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {voiceNews?.map((row) => {
                    return (
                      <TableRow hover key={row?.id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={row?.title} src={row?.image} />
                            <Typography variant="subtitle2">
                              {row?.title ?? 'Null'}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell
                          align="right"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 12,
                          }}
                        >
                          <Link target="_blank" to={row?.voice_url}>
                            <Button variant="outlined">Ses</Button>
                          </Link>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <div>{row?.listening}</div>
                            <Iconify
                              icon="eva:volume-up-outline"
                              sx={{ width: 16, height: 16, mr: 0.5 }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {voiceNews?.total ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={voiceNews?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}

export default PopularNews;
