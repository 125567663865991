import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Alert, Box, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbarStatus } from '@features/general/general.slice';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const snackbarStatus = useSelector((state) => state?.general?.snackbarStatus);
  const dispatch = useDispatch();
  return (
    <>
      <Helmet>
        <title>{`${title} | Popil`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>

      <Snackbar
        open={snackbarStatus.status}
        onClose={() => dispatch(setSnackbarStatus({ status: false }))}
        autoHideDuration={6000}
        action={'action'}
      >
        <Alert
          severity={snackbarStatus?.type === 'success' ? 'success' : 'error'}
        >
          {'notification ' + snackbarStatus?.message}
        </Alert>
      </Snackbar>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
