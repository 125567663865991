export const env = {
  API_URL: 'https://nebula.popil.app/api',
  FRONT_URL: 'https://popil.app',
  ROVER_URL: 'https://rover.popil.app/api',
};

/*
  prod:
  API_URL: 'https://nebula.popil.app/api',
  FRONT_URL: 'https://popil.app',
  ROVER_URL: 'https://rover.popil.app/api',
*/

/*
  test:
  API_URL: 'https://nebula-test.popil.app/api',
  FRONT_URL: 'https://popil.app',
  ROVER_URL: 'https://rover-test.popil.app/api',
*/

/*
  localhost:
  API_URL: 'http://127.0.0.1:8000/api',
  FRONT_URL: 'http://localhost:3000',
  ROVER_URL: 'http://localhost:3001/api',
*/
