import { useRef, useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import Iconify from '@components/shared/Iconify';
import { useDispatch } from 'react-redux';
import {
  changeSourceStatus,
  getSources,
} from '@features/source/source.actions';
import {
  setEditSourceFields,
  setOpenEditSourceModal,
} from '@features/source/source.slice';

// ----------------------------------------------------------------------

export default function SourceMoreMenu({ source, status }) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleStatus = () => {
    dispatch(changeSourceStatus(source?.id));
    dispatch(getSources());
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={handleStatus}>
          {/*          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>*/}
          <ListItemText
            primary={status ? 'Passive' : 'Active'}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(setEditSourceFields(source));
            dispatch(setOpenEditSourceModal(true));
            setIsOpen(false);
          }}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
