import React, { useEffect } from 'react';
import { Page } from '@components';
import {
  Autocomplete,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addUser } from '@features/user/user.actions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function AddUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AddUserSchema = Yup.object().shape({
    username: Yup.string().required('Zorunlu'),
    email: Yup.string()
      .email('Geçerli bir e-posta adresi girin')
      .required('Zorunlu'),
    password: Yup.string().required('Zorunlu'),
    is_admin: Yup.string().required('Zorunlu'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      is_admin: '',
    },
    validationSchema: AddUserSchema,
    onSubmit: async (values) => {
      dispatch(addUser(values)).then((res) => {
        if (res.payload.message === 'username_available') {
          toast.error('Böyle bir kullanıcı adı zaten var.');
        }
        if (res.payload.message === 'email_available') {
          toast.error('Böyle bir e-posta zaten var.');
        }
        if (res.payload.message === 'SUCCESS') {
          toast.success('Kullanıcı oluşturuldu');
          navigate(-1);
        }
      });
    },
  });

  useEffect(() => {
    formik.setFieldValue('is_admin', 0);
  }, []);

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Add User
          </Typography>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              id="outlined-basic"
              onChange={formik.handleChange}
              name="username"
              label="Username"
              variant="outlined"
              fullWidth
              error={formik.touched.username && !!formik.errors.username}
              helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
              id="outlined-basic"
              onChange={formik.handleChange}
              name="email"
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              id="outlined-basic"
              onChange={formik.handleChange}
              name="password"
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              isOptionEqualToValue={(option, value) =>
                option.status === value.status
              }
              options={[
                { label: 'User', status: 0 },
                { label: 'Admin', status: 1 },
              ]}
              defaultValue="User"
              onChange={(event, value) =>
                formik.setFieldValue('is_admin', value?.status)
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formik.touched.is_admin && !!formik.errors.is_admin}
                  helperText={formik.touched.is_admin && formik.errors.is_admin}
                  fullWidth
                  name="is_admin"
                  label="Role"
                />
              )}
            />
            <Button type="submit" variant="outlined">
              Create
            </Button>
          </Stack>
        </form>
      </Container>
    </Page>
  );
}

export default AddUser;
