import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User/Users';
import AddUser from './pages/User/AddUser';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import News from './pages/News/News';
import Notification from './pages/Notification';
import NewsComments from './pages/News/NewsComments';
import AddNews from './pages/News/AddNews';
import TrendNews from './pages/News/TrendNews';
import Sources from './pages/Sources/Sources';
import Categories from './pages/Categories/Categories';
import CategorySources from './pages/Categories/CategorySources';
import Topics from './pages/Topics/Topics';
import AddTopic from './pages/Topics/AddTopic';
import EditTopic from './pages/Topics/EditTopic';
import PopularNews from './pages/News/PopularNews';
import Jobs from './pages/Jobs/Jobs';
import NewsReads from './pages/News/NewsReads';
import DailyNews from './pages/News/DailyNews';
import Settings from './pages/Settings';
import VoiceNews from './pages/News/VoiceNews';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'user/add', element: <AddUser /> },
        { path: 'news', element: <News /> },
        { path: 'news/add', element: <AddNews /> },
        { path: 'news/trends', element: <TrendNews /> },
        { path: 'voice-news', element: <VoiceNews /> },
        { path: 'reads', element: <NewsReads /> },
        { path: 'comments', element: <NewsComments /> },
        { path: 'popular-news', element: <PopularNews /> },
        { path: 'daily-news', element: <DailyNews /> },
        { path: 'notification', element: <Notification /> },
        { path: 'sources', element: <Sources /> },
        { path: 'categories', element: <Categories /> },
        { path: 'categories/:slug/:id', element: <CategorySources /> },
        { path: 'topics', element: <Topics /> },
        { path: 'topics/add', element: <AddTopic /> },
        { path: 'topics/edit/:id', element: <EditTopic /> },
        { path: 'jobs', element: <Jobs /> },
        { path: 'settings', element: <Settings /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
