import React, { useEffect, useState } from 'react';
import { Page } from '@components';
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSettings,
  updateSettings,
} from '@features/settings/settings.actions';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

function Settings() {
  const { settings } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: settings,
    validationSchema: null,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      await dispatch(updateSettings(formData));
      dispatch(getSettings());
      toast.success('Settings has been updated.');
      setLoading(false);
    },
  });

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  return (
    <Page title="Settings">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h3" gutterBottom>
          Settings
        </Typography>
      </Stack>

      <Stack spacing={2}>
        <div>
          <TextField
            id="outlined-basic"
            name="Name"
            onChange={(event) =>
              formik.setFieldValue('name', event.target.value)
            }
            defaultValue={settings?.name}
            label="Name"
            variant="outlined"
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            name="Description"
            onChange={(event) =>
              formik.setFieldValue('description', event.target.value)
            }
            defaultValue={settings?.description}
            multiline
            label="Description"
            variant="outlined"
            error={formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            name="Email"
            onChange={(event) =>
              formik.setFieldValue('email', event.target.value)
            }
            defaultValue={settings?.email}
            label="Email"
            variant="outlined"
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            name="Website"
            onChange={(event) =>
              formik.setFieldValue('website', event.target.value)
            }
            defaultValue={settings?.website}
            label="Email"
            variant="outlined"
            error={formik.touched.website && !!formik.errors.website}
            helperText={formik.touched.website && formik.errors.website}
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            name="Linkedin"
            onChange={(event) =>
              formik.setFieldValue('linkedin', event.target.value)
            }
            defaultValue={settings?.linkedin}
            label="Linkedin"
            variant="outlined"
            error={formik.touched.linkedin && !!formik.errors.linkedin}
            helperText={formik.touched.linkedin && formik.errors.linkedin}
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            name="Facebook"
            onChange={(event) =>
              formik.setFieldValue('facebook', event.target.value)
            }
            defaultValue={settings?.facebook}
            label="Facebook"
            variant="outlined"
            error={formik.touched.facebook && !!formik.errors.facebook}
            helperText={formik.touched.facebook && formik.errors.facebook}
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            name="Instagram"
            onChange={(event) =>
              formik.setFieldValue('facebook', event.target.value)
            }
            defaultValue={settings?.instagram}
            label="Instagram"
            variant="outlined"
            error={formik.touched.instagram && !!formik.errors.instagram}
            helperText={formik.touched.instagram && formik.errors.instagram}
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            name="Twitter"
            onChange={(event) =>
              formik.setFieldValue('facebook', event.target.value)
            }
            defaultValue={settings?.twitter}
            label="Twitter"
            variant="outlined"
            error={formik.touched.twitter && !!formik.errors.twitter}
            helperText={formik.touched.twitter && formik.errors.twitter}
            fullWidth
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            name="Telephone"
            onChange={(event) =>
              formik.setFieldValue('telephone', event.target.value)
            }
            defaultValue={settings?.telephone}
            label="Telephone"
            variant="outlined"
            error={formik.touched.telephone && !!formik.errors.telephone}
            helperText={formik.touched.telephone && formik.errors.telephone}
            fullWidth
          />
        </div>
        <div>
          <FormLabel id="demo-radio-buttons-group-label">
            Show Google Login Button
          </FormLabel>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={
              Boolean(settings?.show_google_login_button) ? 'show' : 'hide'
            }
            name="show_google_login_button"
            onChange={(event, value) => {
              formik.setFieldValue(
                'show_google_login_button',
                value === 'show' ? 1 : 0
              );
            }}
          >
            <FormControlLabel value="show" control={<Radio />} label="Show" />
            <FormControlLabel value="hide" control={<Radio />} label="Hide" />
          </RadioGroup>
        </div>
        <div>
          <FormLabel id="demo-radio-buttons-group-label">
            Show Google News Detail Ads
          </FormLabel>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={Boolean(settings?.show_google_ads) ? 'show' : 'hide'}
            name="show_google_ads"
            onChange={(event, value) => {
              formik.setFieldValue('show_google_ads', value === 'show' ? 1 : 0);
            }}
          >
            <FormControlLabel value="show" control={<Radio />} label="Show" />
            <FormControlLabel value="hide" control={<Radio />} label="Hide" />
          </RadioGroup>
        </div>
        <div>
          <FormLabel id="demo-radio-buttons-group-label">
            Show Google News List Ads
          </FormLabel>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={
              Boolean(settings?.show_google_list_ads) ? 'show' : 'hide'
            }
            name="show_google_list_ads"
            onChange={(event, value) => {
              formik.setFieldValue(
                'show_google_list_ads',
                value === 'show' ? 1 : 0
              );
            }}
          >
            <FormControlLabel value="show" control={<Radio />} label="Show" />
            <FormControlLabel value="hide" control={<Radio />} label="Hide" />
          </RadioGroup>
        </div>
        <div>
          <FormLabel id="demo-radio-buttons-group-label">
            Show Short News AI Support
          </FormLabel>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={
              Boolean(settings?.show_shorten_news_ai) ? 'show' : 'hide'
            }
            name="show_shorten_news_ai"
            onChange={(event, value) => {
              formik.setFieldValue(
                'show_shorten_news_ai',
                value === 'show' ? 1 : 0
              );
            }}
          >
            <FormControlLabel value="show" control={<Radio />} label="Show" />
            <FormControlLabel value="hide" control={<Radio />} label="Hide" />
          </RadioGroup>
        </div>
        <div>
          <FormLabel id="demo-radio-buttons-group-label">
            Show Daily Button
          </FormLabel>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={
              Boolean(settings?.show_daily_button) ? 'show' : 'hide'
            }
            name="show_daily_button"
            onChange={(event, value) => {
              formik.setFieldValue(
                'show_daily_button',
                value === 'show' ? 1 : 0
              );
            }}
          >
            <FormControlLabel value="show" control={<Radio />} label="Show" />
            <FormControlLabel value="hide" control={<Radio />} label="Hide" />
          </RadioGroup>
        </div>
        <div>
          <FormLabel id="demo-radio-buttons-group-label">
            Show Clickbait Button
          </FormLabel>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={
              Boolean(settings?.show_clickbait_button) ? 'show' : 'hide'
            }
            name="show_clickbait_button"
            onChange={(event, value) => {
              formik.setFieldValue(
                'show_clickbait_button',
                value === 'show' ? 1 : 0
              );
            }}
          >
            <FormControlLabel value="show" control={<Radio />} label="Show" />
            <FormControlLabel value="hide" control={<Radio />} label="Hide" />
          </RadioGroup>
        </div>
        <LoadingButton
          onClick={() => formik.handleSubmit()}
          loading={loading}
          type="submit"
          variant="contained"
        >
          Update
        </LoadingButton>
      </Stack>
    </Page>
  );
}

export default Settings;
