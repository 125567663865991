import React, { useEffect, useState } from 'react';
import { Iconify, Page } from '@components';
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import Scrollbar from '@components/shared/Scrollbar';
import { UserListHead } from 'src/components/pages/user';
import { AddJobsModal } from '@components/pages';
import axios from 'axios';
import { env } from '@env';

function Jobs(props) {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [jobs, setJobs] = useState([]);

  const getJobs = async () => {
    const data = await axios
      .get(`${env.ROVER_URL}/v1/jobs/list`)
      .then((res) => res?.data?.data);

    setJobs(data);
  };

  const deleteJob = async (id) => {
    const data = await axios
      .delete(`${env.ROVER_URL}/v1/jobs/delete/${id}`)
      .then((res) => res?.data);

    getJobs();
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <Page title="Popular News">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Jobs
          </Typography>
          <Button
            variant="contained"
            onClick={() => setOpenAddModal(true)}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Job
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'id', label: 'ID', alignRight: false },
                    { id: 'frequency', label: 'Frequency', alignRight: false },
                    { id: 'cron', label: 'Cron', alignRight: false },
                    { id: 'user', label: 'User', alignRight: false },
                    { id: 'command', label: 'Command', alignRight: false },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {jobs?.map((job) => (
                    <TableRow hover tabIndex={-1}>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" spacing={2}>
                          {job?.id}
                        </Stack>
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none">
                        {job?.frequency}
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none">
                        {job?.cron}
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none">
                        {job?.username}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        style={{ maxWidth: 300, minWidth: 300 }}
                        padding="none"
                      >
                        <Stack
                          direction="row"
                          backgroundColor="black"
                          alignItems="center"
                          spacing={2}
                          padding={1.5}
                          borderRadius={1}
                          style={{ cursor: 'pointer' }}
                        >
                          <Typography color="white" variant="subtitle2" noWrap>
                            {job?.command}
                          </Typography>
                        </Stack>
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          onClick={() => deleteJob(job?.id)}
                          color="error"
                        >
                          Sil
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>

      <AddJobsModal
        isOpen={openAddModal}
        onClose={() => setOpenAddModal(false)}
        getJobs={getJobs()}
      />
    </Page>
  );
}

export default Jobs;
