import React, { useEffect, useState } from 'react';
import { CustomModal } from '@components';
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { env } from '@env';

function AddJobsModal({ isOpen, onClose, getJobs }) {
  const { user } = useSelector((state) => state.user);
  const [frequency, setFrequency] = useState('everyMinute');
  const [minute, setMinute] = useState('*');
  const [hour, setHour] = useState('*');
  const [dayOfMonth, setDayOfMonth] = useState('*');
  const [month, setMonth] = useState('*');
  const [dayOfWeek, setDayOfWeek] = useState('*');

  const crons = {
    everyMinute: '* * * * *',
    hourly: '0 * * * *',
    weekly: '0 0 * * 0',
    nightly: '0 0 * * *',
    monthly: '0 0 1 * *',
  };

  const JobsSchema = Yup.object().shape({
    command: Yup.string().required('Command is required'),
    frequency: Yup.string().required('Frequency is required'),
    cron: Yup.string().required('Cron is required'),
    description: Yup.string().required('Description is required'),
    username: Yup.string().required('Username is required'),
  });

  const formik = useFormik({
    initialValues: {
      command: '',
      frequency: frequency,
      cron: crons?.everyMinute,
      description: '',
      username: user?.username,
    },
    validationSchema: JobsSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = await axios
        .post(`${env.ROVER_URL}/v1/jobs/add`, values)
        .then((res) => res.data);

      onClose();
      getJobs();
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      'cron',
      `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`
    );
  }, [minute, hour, dayOfMonth, month, dayOfWeek]);

  const handleFreqChange = (value) => {
    setFrequency(value);
    formik.setFieldValue('frequency', value);

    if (value === 'custom') {
      formik.setFieldValue(
        'cron',
        `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`
      );
    } else {
      formik.setFieldValue('cron', crons[value]);
    }
  };

  return (
    <CustomModal width={800} isOpen={isOpen} onClose={onClose}>
      <Stack pb={2}>
        <h3>New Scheduled Job</h3>
      </Stack>

      <Stack>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Command"
          name="command"
          value={formik?.values?.command}
          onChange={formik.handleChange}
          placeholder="php8.1 /home/forge/default/artisan schedule:run"
          required
        />
      </Stack>

      <Stack mt={2}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Description"
          name="description"
          multiline
          rows={4}
          value={formik?.values?.description}
          onChange={formik.handleChange}
          required
        />
      </Stack>

      <Stack mt={2}>
        <FormLabel id="demo-radio-buttons-group-label">Frequency</FormLabel>

        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="everyMinute"
          name="frequency"
          value={frequency}
          onChange={(event, value) => handleFreqChange(value)}
        >
          <FormControlLabel
            value="everyMinute"
            control={<Radio />}
            label="Every Minute"
          />
          <FormControlLabel value="hourly" control={<Radio />} label="Hourly" />
          <FormControlLabel
            value="nightly"
            control={<Radio />}
            label="Nightly"
          />
          <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
          <FormControlLabel
            value="monthly"
            control={<Radio />}
            label="Monthly"
          />
          <FormControlLabel value="custom" control={<Radio />} label="Custom" />
        </RadioGroup>
      </Stack>

      {frequency === 'custom' ? (
        <>
          <Stack mt={2}>
            <FormLabel id="demo-radio-buttons-group-label">
              Custom Schedule
            </FormLabel>
          </Stack>

          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="minute"
              name="minute"
              placeholder="minute"
              defaultValue="*"
              value={minute}
              onChange={(event) => setMinute(event?.target?.value)}
            />
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="hour"
              name="hour"
              placeholder="hour"
              defaultValue="*"
              value={hour}
              onChange={(event) => setHour(event?.target?.value)}
            />
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="day of month"
              name="day-of-month"
              placeholder="day of month"
              defaultValue="*"
              value={dayOfMonth}
              onChange={(event) => setDayOfMonth(event?.target?.value)}
            />
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="month"
              name="month"
              placeholder="month"
              defaultValue="*"
              value={month}
              onChange={(event) => setMonth(event?.target?.value)}
            />
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="day of week"
              name="day-of-week"
              placeholder="day of week"
              defaultValue="*"
              value={dayOfWeek}
              onChange={(event) => setDayOfWeek(event?.target?.value)}
            />
          </Stack>
        </>
      ) : null}

      <Stack mt={2}>
        <LoadingButton
          onClick={() => formik.handleSubmit()}
          loading={false}
          type="submit"
          variant="contained"
        >
          Create
        </LoadingButton>
      </Stack>
    </CustomModal>
  );
}

export default AddJobsModal;
