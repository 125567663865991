import { useEffect } from 'react';
import { env } from '@env';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Iconify, Label, Page, Scrollbar } from '@components';
import { UserListHead } from '../../components/pages/user';
import { EditSourceModal } from '@components/pages';
import { useDispatch, useSelector } from 'react-redux';
import { getTopics } from '@features/general/general.actions';
import TopicMoreMenu from '@components/pages/topics/TopicMoreMenu';
import AddTopicModal from '@components/pages/topics/AddTopicModal';
import { Link as RouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import { setTopics } from '@features/general/general.slice';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '' },
  { id: '' },
  { id: 'keys', label: 'Keys', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function Topics() {
  const dispatch = useDispatch();

  const { topics } = useSelector((state) => state.general);

  useEffect(() => {
    dispatch(getTopics());
  }, [dispatch]);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedTopics = Array?.from(topics);
    const [movedItem] = reorderedTopics?.splice(result.source.index, 1);
    reorderedTopics?.splice(result.destination.index, 0, movedItem);

    if (reorderedTopics) {
      dispatch(setTopics(reorderedTopics));
    }

    const updatedTopics = reorderedTopics?.map((topic, index) => ({
      id: topic.id,
      order: index + 1,
    }));

    try {
      await axios.post(`${env.API_URL}/admin/topics/sortTopics`, {
        topics: updatedTopics,
      });
      console.log('Topics order updated successfully');
    } catch (error) {
      console.error('Error updating topics order:', error);
    }
  };

  return (
    <Page title="Topics">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Topics
          </Typography>

          <Button
            variant="contained"
            component={RouterLink}
            to="add"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Topic
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              {topics ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="topics">
                    {(provided) => (
                      <Table
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <UserListHead headLabel={TABLE_HEAD} />
                        <TableBody>
                          {topics?.map((row, index) => {
                            const {
                              id,
                              title,
                              image,
                              cdn_image,
                              tags,
                              status,
                            } = row;
                            return (
                              <Draggable
                                key={id}
                                draggableId={id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <TableRow
                                    hover
                                    key={id}
                                    tabIndex={-1}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <TableCell align="left">{id}</TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      padding="none"
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Avatar alt={title} src={cdn_image} />
                                        <Typography variant="subtitle2" noWrap>
                                          {title ?? 'Null'}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="left">{tags}</TableCell>
                                    <TableCell align="left">
                                      <Label
                                        variant="ghost"
                                        color={status ? 'success' : 'error'}
                                      >
                                        {status ? 'active' : 'passive'}
                                      </Label>
                                    </TableCell>

                                    <TableCell align="right">
                                      <TopicMoreMenu
                                        topic={row}
                                        status={status}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </TableBody>
                      </Table>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : null}
            </TableContainer>
          </Scrollbar>

          <EditSourceModal />
          <AddTopicModal />

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
    </Page>
  );
}
