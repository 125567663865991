import { CustomModal, Label } from '@components';
import axios from 'axios';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddComments from '@components/pages/comments/AddComments';
import { setOpenAddCommentModal } from '@features/news/news.slice';
import { addFakeComments } from '@features/news/news.actions';
import { toast } from 'react-toastify';

function NewsCommentAddModal() {
  const dispatch = useDispatch();

  const { newsDetail, openAddCommentModal } = useSelector(
    (state) => state.news
  );

  const onSave = async (data) => {
    const response = await dispatch(
      addFakeComments({
        newsId: newsDetail.id,
        comments: data,
      })
    );

    if (response) {
      dispatch(setOpenAddCommentModal(false));
      return true;
    } else {
      toast.error('error');
      return false;
    }
  };

  return (
    <CustomModal
      width={900}
      title="Add Comment"
      isOpen={openAddCommentModal}
      onClose={() => dispatch(setOpenAddCommentModal(false))}
    >
      <AddComments onSave={onSave} />
    </CustomModal>
  );
}

export default NewsCommentAddModal;
