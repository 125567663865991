import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getSources = createAsyncThunk('source/getSources', async () => {
  return (await axios.get(`${env.API_URL}/admin/getSources`)).data;
});

export const getSourceByCategory = createAsyncThunk(
  'source/getSourceByCategory',
  async (categoryId) => {
    return (
      await axios.get(`${env.API_URL}/admin/getSourceByCategory/${categoryId}`)
    ).data;
  }
);

export const changeSourceStatus = createAsyncThunk(
  'source/changeSourceStatus',
  async (id) => {
    return (await axios.post(`${env.API_URL}/admin/changeSourceStatus`, { id }))
      .data;
  }
);

export const addSource = createAsyncThunk('source/addSource', async (data) => {
  return (await axios.post(`${env.API_URL}/admin/addSource`, data)).data;
});

export const editSource = createAsyncThunk(
  'source/editSource',
  async (data) => {
    return (await axios.post(`${env.API_URL}/admin/editSource`, data)).data;
  }
);
