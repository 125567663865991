import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async (data) => {
    return (await axios.get(`${env.API_URL}/admin/getCategories`, data)).data;
  }
);

export const addSourceToCategory = createAsyncThunk(
  'categories/addSourceToCategory',
  async (data) => {
    return (await axios.post(`${env.API_URL}/admin/addSourceToCategory`, data))
      .data;
  }
);

export const deleteSourceFromCategory = createAsyncThunk(
  'categories/addSourceToCategory',
  async (id) => {
    return (
      await axios.post(`${env.API_URL}/admin/deleteSourceFromCategory`, { id })
    ).data;
  }
);
