import React from 'react';
import { CustomModal } from '@components';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { addSourceToCategory } from '@features/categories/categories.actions';
import { getSourceByCategory } from '@features/source/source.actions';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { setOpenAddSourceCategoryModal } from '@features/categories/categories.slice';
import { useParams } from 'react-router-dom';

function AddSourceCategoryModal() {
  const dispatch = useDispatch();
  const params = useParams();
  const { openAddSourceCategoryModal, sourcesLoading } = useSelector(
    (state) => state.categories
  );
  const { sources } = useSelector((state) => state.source);

  const formik = useFormik({
    initialValues: {
      sourceId: '',
    },
    onSubmit: async (values) => {
      console.log(values);

      dispatch(
        addSourceToCategory({
          source: values?.sourceId,
          category: Number(params?.id),
        })
      ).then((res) => {
        if (res?.payload?.message === 'AVAILABLE') {
          toast.error('Bu kaynak bu kategoride zaten mevcut.');
        }
        if (res?.payload?.message === 'SUCCESS') {
          toast.success('Kaynak eklendi.');
          dispatch(setOpenAddSourceCategoryModal(false));
        }
        dispatch(getSourceByCategory(params?.id));
      });
    },
  });

  return (
    <CustomModal
      isOpen={openAddSourceCategoryModal}
      onClose={() => dispatch(setOpenAddSourceCategoryModal(false))}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} p={2}>
          <h3>Add to {params?.slug?.toUpperCase()}</h3>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            getOptionLabel={(source) => source.name.toUpperCase()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={sources}
            onChange={(event, value) =>
              formik.setFieldValue('sourceId', value?.id)
            }
            fullWidth
            renderInput={(params) => (
              <TextField {...params} fullWidth name="source" label="Sources" />
            )}
          />
          <LoadingButton
            loading={sourcesLoading}
            type="submit"
            variant="contained"
          >
            Ekle
          </LoadingButton>
        </Stack>
      </form>
    </CustomModal>
  );
}

export default AddSourceCategoryModal;
