import React, { useEffect, useState } from 'react';
import { env } from '@env';

import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { UserListHead } from 'src/components/pages/user';
import { CommentMoreMenu } from 'src/components/pages/new-comments';

import { Iconify, Label, Page, Scrollbar, SearchNotFound } from '@components';

import axios from 'axios';
import { fToNow } from '@utils/formatTime';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function NewsComments() {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [tableData, setTableData] = useState();

  const getTableData = (page = 0, limit = 5) => {
    axios
      .get(
        `${env.API_URL}/admin/getAllNewsComment?page=${page + 1}&limit=${limit}`
      )
      .then((res) => {
        setTableData(res.data);
      });
  };

  const handleChangePage = (event, newPage) => {
    getTableData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getTableData(0, event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <Page title="News Comment">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            News Comments
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: '' },
                    { id: 'user', label: 'User', alignRight: false },
                    { id: 'comment', label: 'Comment', alignRight: false },
                    { id: 'news', label: 'News', alignRight: false },
                    { id: 'date', label: 'Date', alignRight: false },
                    { id: 'status', label: 'Status', alignRight: false },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {tableData?.data?.map((item) => (
                    <TableRow hover key={item.id} tabIndex={-1}>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar src={item?.user?.cdn_image} />
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{item?.user?.username}</TableCell>
                      <TableCell align="left">{item?.description}</TableCell>
                      <TableCell align="left">{item?.news?.title}</TableCell>
                      <TableCell align="left">
                        {fToNow(item?.created_at)}
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={item.status ? 'success' : 'error'}
                        >
                          {item.status ? 'active' : 'passive'}
                        </Label>
                      </TableCell>

                      <TableCell align="right">
                        <CommentMoreMenu
                          commentStatus={item.status}
                          getTableData={getTableData}
                          page={page}
                          commentId={item.id}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {tableData?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 15, 25]}
            component="div"
            count={tableData?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
