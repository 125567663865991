import React from 'react';
import { CustomModal } from '@components';
import { Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { addSource, getSources } from '@features/source/source.actions';
import { setOpenAddSourceModal } from '@features/source/source.slice';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

function AddSourceModal() {
  const dispatch = useDispatch();
  const { openAddSourceModal, sourcesLoading } = useSelector(
    (state) => state.source
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      slug: '',
    },
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      dispatch(addSource(formData)).then((res) => {
        if (res?.payload?.message === 'SUCCESS') {
          toast.success('Kaynak eklendi.');
        }
        dispatch(getSources());
        dispatch(setOpenAddSourceModal(false));
        resetForm();
      });
    },
  });

  return (
    <CustomModal
      isOpen={openAddSourceModal}
      onClose={() => dispatch(setOpenAddSourceModal(false))}
    >
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Stack spacing={2}>
          <Stack
            spacing={1}
            border={1}
            borderColor="#f2f2f2"
            borderRadius={2}
            p={2}
            mt={4}
          >
            <label style={{ fontWeight: 'bold' }}>Logo</label>
            <input
              type="file"
              name="image"
              onChange={(event) =>
                formik.setFieldValue('image', event.target.files[0])
              }
            />
          </Stack>
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Name"
            name="name"
            onChange={formik.handleChange}
          />
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Slug"
            name="slug"
            onChange={formik.handleChange}
          />
          <LoadingButton
            loading={sourcesLoading}
            type="submit"
            variant="contained"
          >
            Ekle
          </LoadingButton>
        </Stack>
      </form>
    </CustomModal>
  );
}

export default AddSourceModal;
