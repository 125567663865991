import React from 'react';
import {
  setNewsDetail,
  setOpenEditNewsModal,
  setOpenNewsNotificationModal,
  setOpenAddCommentModal,
} from '@features/news/news.slice';
import {
  setNotificationTitle,
  setNotificationTitleType,
} from '@features/general/general.slice';
import Swal from 'sweetalert2';
import axios from 'axios';
import { env } from '@env';
import {
  deleteNews,
  getDailyNews,
  updateDailyNews,
} from '@features/news/news.actions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

function useNews() {
  const dispatch = useDispatch();

  const handleEditNewsModal = (news) => {
    dispatch(setNewsDetail(news));
    dispatch(setOpenEditNewsModal(true));
  };

  const handleNewsNotificationModal = (news) => {
    dispatch(setNewsDetail(news));
    dispatch(setNotificationTitle(news?.category?.name));
    dispatch(setNotificationTitleType(news?.category?.name));
    dispatch(setOpenNewsNotificationModal(true));
  };

  const updatePopularNews = async (newsId) => {
    Swal.fire({
      title: 'Are you sure you want to add this news to popular news?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${env.API_URL}/admin/updatePopularNews`, {
          newsId,
          status: 1,
        });
      }
    });
  };

  const onDeleteNews = (newsId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteNews(newsId));
        toast.success('Your file has been deleted.');
      }
    });
  };

  const handleUpdateDailyNews = async (newsId) => {
    await Swal.fire({
      title: 'Are you sure you want to add this news to daily news?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(updateDailyNews({ newsId, isDaily: 1 }));
      }
    });
  };

  const handleAddCommentModal = (news) => {
    dispatch(setNewsDetail(news));
    dispatch(setOpenAddCommentModal(true));
  };

  return {
    handleNewsNotificationModal,
    handleEditNewsModal,
    updatePopularNews,
    handleUpdateDailyNews,
    onDeleteNews,
    handleAddCommentModal,
  };
}

export default useNews;
