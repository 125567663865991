import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getSources } from '@features/source/source.actions';
import { getCategories } from '@features/categories/categories.actions';
import { addNews } from '@features/news/news.actions';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

function AddNews() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [content, setContent] = useState('');
  const [slug, setSlug] = useState('');
  const [loading, setLoading] = useState(false);
  const { sources } = useSelector((state) => state.source);
  const { categories } = useSelector((state) => state.categories);

  function slugify(text) {
    let trMap = {
      çÇ: 'c',
      ğĞ: 'g',
      şŞ: 's',
      üÜ: 'u',
      ıİ: 'i',
      öÖ: 'o',
    };
    for (let key in trMap) {
      text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
    }
    return text
      .replace(/[^-a-zA-Z0-9\s]+/gi, '')
      .replace(/\s/gi, '-')
      .replace(/[-]+/gi, '-')
      .toLowerCase();
  }

  const AddNewsSchema = Yup.object().shape({
    title: Yup.string().required('Zorunlu'),
    image: Yup.string().required('Fotoğraf alanı zorunlu'),
    sourceId: Yup.string().required('Kaynak alanı zorunlu'),
    categoryId: Yup.string().required('Kategori alanı zorunlu'),
  });

  const formik = useFormik({
    initialValues: {
      image: '',
      title: '',
      url: null,
      sourceId: '',
      categoryId: '',
    },
    validationSchema: AddNewsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const data = { contents: content, slug, ...values };
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      dispatch(addNews(formData)).then((res) => {
        if (res?.payload?.message === 'IMAGE_FIELD_MUST') {
          toast.error('Fotoğraf alanı zorunludur.');
        }
        if (res?.payload?.message === 'URL_ALREADY_EXISTS') {
          toast.error('Böyle bir url zaten mevcut.');
        }
        if (res?.payload?.message === 'SUCCESS') {
          toast.success('İçerik eklendi.');
          navigate(-1);
        }
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    dispatch(getSources());
    dispatch(getCategories());
  }, [navigate]);

  useEffect(() => {
    if (sources) {
      formik.setFieldValue(
        'sourceId',
        sources?.find((source) => source.slug === 'popil').id
      );
    }
  }, [sources]);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h3" gutterBottom>
            Add News
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <div>
            <Typography fontWeight="bold" gutterBottom>
              Fotoğraf:
            </Typography>
            <input
              type="file"
              name="image"
              onChange={(event) => {
                formik.setFieldValue('image', event.currentTarget.files[0]);
              }}
            />
            <div style={{ color: 'red', marginTop: 10 }}>
              {formik.touched.image && formik.errors.image}
            </div>
          </div>
          <Divider />
          <div>
            <TextField
              id="outlined-basic"
              name="title"
              onChange={(event) => {
                setSlug(slugify(event.target.value));
                formik.setFieldValue('title', event.target.value);
              }}
              label="Title"
              variant="outlined"
              error={formik.touched.title && !!formik.errors.title}
              helperText={formik.touched.title && formik.errors.title}
              fullWidth
            />
          </div>
          <div>
            <TextField
              id="outlined-basic"
              label="Slug"
              value={slug}
              variant="outlined"
              fullWidth
              focused={slug}
            />
          </div>
          <div>
            <TextField
              id="outlined-basic"
              name="url"
              onChange={formik.handleChange}
              label="Url (Opsiyonel)"
              variant="outlined"
              error={formik.touched.url && !!formik.errors.url}
              helperText={formik.touched.url && formik.errors.url}
              fullWidth
            />
          </div>
          {sources ? (
            <div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                getOptionLabel={(source) => source.name.toUpperCase()}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                options={sources}
                defaultValue={sources?.find(
                  (source) => source.slug === 'popil'
                )}
                onChange={(event, value) =>
                  formik.setFieldValue('sourceId', value?.id)
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!formik.errors.sourceId}
                    helperText={formik.errors.sourceId}
                    fullWidth
                    name="sourceId"
                    label="Sources"
                  />
                )}
              />
            </div>
          ) : null}
          {categories ? (
            <div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                getOptionLabel={(category) => category.name.toUpperCase()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={categories}
                onChange={(event, value) =>
                  formik.setFieldValue('categoryId', value?.id)
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.categoryId && !!formik.errors.categoryId
                    }
                    helperText={
                      formik.touched.categoryId && formik.errors.categoryId
                    }
                    fullWidth
                    name="categoryId"
                    label="Categories"
                  />
                )}
              />
            </div>
          ) : null}
          <div>
            <label>Content</label>
            <ReactQuill
              name="content"
              theme="snow"
              value={content}
              onChange={setContent}
            />
          </div>
          <LoadingButton loading={loading} type="submit" variant="outlined">
            Add
          </LoadingButton>
        </Stack>
      </form>
    </Container>
  );
}

export default AddNews;
