import React from 'react';
import 'antd/dist/antd.min.css';
import { Modal } from 'antd';

function CustomModal({ title, isOpen, onClose, children, width }) {
  return (
    <Modal
      width={width}
      title={title}
      visible={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
    >
      {children}
    </Modal>
  );
}

export default CustomModal;
