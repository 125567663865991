import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import {
  CustomModal,
  Iconify,
  Page,
  Scrollbar,
  SearchNotFound,
} from '@components';

import { UserListHead } from 'src/components/pages/user';
// mock
import axios from 'axios';
import { FormProvider, RHFTextField } from '@components/hook-form';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { setSnackbarStatus } from '@features/general/general.slice';
import { env } from '@env';
import { fToNow } from '@utils/formatTime';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function User() {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [notifications, setNotifications] = useState();

  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const getNotification = (page = 0, limit = 5) => {
    axios
      .get(
        `${env.API_URL}/admin/getAllNotification?page=${
          page + 1
        }&limit=${limit}`
      )
      .then((res) => {
        setNotifications(res.data);
      });
  };

  const handleChangePage = (event, newPage) => {
    getNotification(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getNotification(0, event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getNotification();
  }, []);

  const FormSchema = Yup.object().shape({
    title: Yup.string().required('Username is required'),
    body: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    title: '',
    body: '',
    data: null,
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    const values = methods.getValues();
    axios
      .post(`${env.ROVER_URL}/v1/notifications/push-custom`, values)
      .then(() => {
        dispatch(
          setSnackbarStatus({
            status: true,
            message: 'push notify is success',
            type: 'success',
          })
        );
      });
  };

  return (
    <Page title="Notifications">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>
          <Button
            variant="contained"
            onClick={() => setOpenModal(true)}
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Send Notification
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'title', label: 'Title', alignRight: false },
                    { id: 'body', label: 'Body', alignRight: false },
                    { id: 'type', label: 'Type', alignRight: false },
                    { id: 'seen', label: 'Seen', alignRight: false },
                    { id: 'date', label: 'Date', alignRight: false },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {notifications?.data?.map((item) => (
                    <TableRow hover key={item?.id} tabIndex={-1}>
                      <TableCell align="left">{item?.title}</TableCell>
                      <TableCell align="left">{item?.body}</TableCell>
                      <TableCell align="left">{item?.type}</TableCell>
                      <TableCell align="left">{item?.seen}</TableCell>
                      <TableCell align="left">
                        {fToNow(item?.created_at)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {notifications?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={notifications?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <CustomModal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField name="title" label="Title" />
            <RHFTextField name="body" label="Body" />
            <RHFTextField name="data" label="Data" />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Send
            </LoadingButton>
          </Stack>
        </FormProvider>
      </CustomModal>
    </Page>
  );
}
