import { createSlice } from '@reduxjs/toolkit';
import { getTopicById, getTopics } from './general.actions';

const generalReducer = createSlice({
  name: 'general',
  initialState: {
    snackbarStatus: { message: '', status: false, type: 'success' },
    topics: null,
    topicById: null,
    openAddTopicModal: false,
    openEditTopicModal: false,
    editTopicFields: null,
    notificationTitle: null,
    notificationTitleType: null,
  },
  reducers: {
    setTopics(state, action) {
      state.topics = action.payload;
    },
    setSnackbarStatus(state, action) {
      state.snackbarStatus = action.payload;
    },
    setOpenAddTopicModal(state, action) {
      state.openAddTopicModal = action.payload;
    },
    setOpenEditTopicModal(state, action) {
      state.openEditTopicModal = action.payload;
    },
    setEditTopicFields(state, action) {
      state.editTopicFields = action.payload;
    },
    setNotificationTitle(state, action) {
      state.notificationTitle = action.payload;
    },
    setNotificationTitleType(state, action) {
      state.notificationTitleType = action.payload;
    },
  },
  extraReducers: {
    [getTopics.fulfilled]: (state, action) => {
      state.topics = action.payload;
    },
    [getTopicById.fulfilled]: (state, action) => {
      state.topicById = action.payload;
    },
  },
});

export default generalReducer.reducer;

export const {
  setSnackbarStatus,
  setOpenAddTopicModal,
  setOpenEditTopicModal,
  setEditTopicFields,
  setNotificationTitle,
  setNotificationTitleType,
  setTopics,
} = generalReducer.actions;
