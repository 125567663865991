import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const setUser = createAsyncThunk('user/setUser', async (data) => {
  return (await axios.post(`${env.API_URL}/admin/auth/login`, data)).data;
});

export const resetUser = createAsyncThunk('user/resetUser', async (data) => {
  return (await axios.post(`${env.API_URL}/admin/auth/logout`, data)).data;
});

export const addUser = createAsyncThunk('user/searchUser', async (data) => {
  return (await axios.post(`${env.API_URL}/admin/addUser`, data)).data;
});

export const searchUser = createAsyncThunk('user/searchUser', async (text) => {
  return (
    await axios.post(`${env.API_URL}/admin/searchUser`, { username: text })
  ).data;
});
