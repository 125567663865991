import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
// components
import { Page } from '@components';

import { env } from '@env';
// sections
import { AppWidgetSummary } from 'src/components/pages/app';

import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [totalUser, setTotalUser] = useState(0);
  const [totalNews, setTotalNews] = useState(0);
  const [totalNewsComment, setTotalNewsComment] = useState(0);
  const [totalSource, setTotalSource] = useState(0);
  const [totalDownload, setTotalDownload] = useState(0);
  const [totalNewsReads, setTotalNewsReads] = useState(0);

  const user = useSelector((state) => state.user.user);

  if (!user) {
    location.href = '/login';
  }

  const getTotals = () => {
    axios.get(`${env.API_URL}/admin/analytics/getTotalUser`).then((res) => {
      setTotalUser(res.data);
    });
    axios.get(`${env.API_URL}/admin/analytics/getTotalNews`).then((res) => {
      setTotalNews(res.data);
    });
    axios
      .get(`${env.API_URL}/admin/analytics/getTotalNewsComment`)
      .then((res) => {
        setTotalNewsComment(res.data);
      });
    axios.get(`${env.API_URL}/admin/analytics/getTotalSource`).then((res) => {
      setTotalSource(res.data);
    });
    axios.get(`${env.API_URL}/admin/analytics/getTotalDownload`).then((res) => {
      setTotalDownload(res.data);
    });
    axios
      .get(`${env.API_URL}/admin/analytics/getTotalNewsReads`)
      .then((res) => {
        setTotalNewsReads(res.data);
      });
  };

  useEffect(() => {
    getTotals();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total User"
              total={totalUser}
              icon={'ant-design:user-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total News"
              total={totalNews}
              color="info"
              icon={'ant-design:border-outer-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total News Reads"
              total={totalNewsReads}
              color="info"
              icon={'ant-design:border-outer-outline'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Comment"
              total={totalNewsComment}
              color="info"
              icon={'ant-design:comment-outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Source"
              total={totalSource}
              color="info"
              icon={'ant-design:holder-outlined'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total IOS"
              total={totalDownload?.ios}
              color="info"
              icon="cil:apple"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Android"
              total={totalDownload?.android}
              color="info"
              icon="icon-park-outline:android"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Download"
              total={totalDownload?.total}
              color="info"
              icon="tabler:download"
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
