import { useEffect } from 'react';
import { env } from '@env';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Iconify, Label, Page, Scrollbar } from '@components';
import { UserListHead } from '../../components/pages/user';
import { EditSourceModal } from '@components/pages';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '@features/categories/categories.actions';
import AddSourceModal from '@components/pages/sources/AddSourceModal';
import { setOpenAddSourceModal } from '@features/source/source.slice';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '' },
  { id: '' },
  { id: 'slug', label: 'Slug', alignRight: false },
  { id: 'totalSources', label: 'Total Sources', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', label: '', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function Categories() {
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <Page title="Categories">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
          <Button
            variant="contained"
            onClick={() => dispatch(setOpenAddSourceModal(true))}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Category
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {categories?.map((row) => {
                    const { id, name, slug, image, sources_count, status } =
                      row;
                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell align="left">{id}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={name} src={image} />
                            <Typography variant="subtitle2" noWrap>
                              {name ?? 'Null'}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{slug}</TableCell>
                        <TableCell align="left">{sources_count}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={status ? 'success' : 'error'}
                          >
                            {status ? 'active' : 'passive'}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <Link to={`/dashboard/categories/${slug}/${id}`}>
                            <Button variant="contained">Sources</Button>
                          </Link>
                        </TableCell>
                        {/*
                        <TableCell align="right">
                          <SourceMoreMenu source={row} status={status} />
                        </TableCell>*/}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <EditSourceModal />
          <AddSourceModal />

          {/*      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />*/}
        </Card>
      </Container>
    </Page>
  );
}
