import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getNews = createAsyncThunk(
  'news/getNews',
  async ({ category, page, showHiddenSource = 0 }) => {
    return (
      await axios.get(
        `${env.API_URL}/getNews/${category}?page=${page}&showHiddenSource=${showHiddenSource}`
      )
    ).data;
  }
);
export const addNews = createAsyncThunk('news/addNews', async (data) => {
  return (await axios.post(`${env.API_URL}/admin/addNews`, data)).data;
});

export const deleteNews = createAsyncThunk(
  'news/deleteNews',
  async (newsId) => {
    return (await axios.post(`${env.API_URL}/admin/deleteNews`, { newsId }))
      .data;
  }
);

export const editNews = createAsyncThunk('news/editNews', async (data) => {
  return (await axios.post(`${env.API_URL}/admin/editNews`, data)).data;
});

export const getDailyNews = createAsyncThunk('news/getDailyNews', async () => {
  return (await axios.get(`${env.API_URL}/admin/daily-news/getDailyNews`)).data;
});

export const updateDailyNews = createAsyncThunk(
  'news/updateDailyNews',
  async ({ newsId, isDaily }) => {
    return (
      await axios.post(`${env.API_URL}/admin/daily-news/updateDailyNews`, {
        newsId,
        isDaily,
      })
    ).data;
  }
);

export const addFakeComments = createAsyncThunk(
  'news/addFakeComments',
  async (data) => {
    return (await axios.post(`${env.API_URL}/admin/addFakeUserComments`, data))
      .data;
  }
);
