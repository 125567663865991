import { useEffect, useState } from 'react';
import { env } from '@env';

import {
  Avatar,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { UserListHead } from 'src/components/pages/user';

import { Page, Scrollbar, SearchNotFound } from '@components';

import axios from 'axios';
import { fToNow } from '@utils/formatTime';

// ----------------------------------------------------------------------

export default function NewsReads() {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [tableData, setTableData] = useState();

  const getTableData = (page = 0) => {
    axios.get(`${env.API_URL}/admin/getNewsReads?page=${page}`).then((res) => {
      setTableData(res.data);
    });
  };

  const handleChangePage = (event, newPage) => {
    getTableData(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <Page title="News Comment">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            News Reads
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'user', label: 'User', alignRight: false },
                    { id: 'news', label: 'News', alignRight: false },
                    { id: 'news_read', label: 'News Read', alignRight: false },
                    { id: 'date', label: 'Date', alignRight: false },
                  ]}
                />
                <TableBody>
                  {tableData?.data?.map((item) => (
                    <TableRow hover key={item.id} tabIndex={-1}>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar src={item?.user?.cdn_image} />
                          <Typography variant="subtitle2" noWrap>
                            {item?.user?.username ?? item?.user?.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        {item?.news?.title ?? 'Deleted'}
                      </TableCell>

                      <TableCell align="left">
                        {item?.news?.views ?? 'Deleted'}
                      </TableCell>

                      <TableCell align="left">
                        {fToNow(item?.created_at)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {tableData?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 15, 25]}
            component="div"
            count={tableData?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
}
