import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getSettings = createAsyncThunk(
  'settings/getSettings',
  async () => {
    return (await axios.get(`${env.API_URL}/admin/settings`)).data;
  }
);

export const updateSettings = createAsyncThunk(
  'settings/updateSettings',
  async (data) => {
    return (await axios.post(`${env.API_URL}/admin/settings/update`, data))
      .data;
  }
);
