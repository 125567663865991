import React, { useEffect, useState } from 'react';
import { Page } from '@components';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateDailyNews, getDailyNews } from '@features/news/news.actions';
import Scrollbar from '@components/shared/Scrollbar';
import { UserListHead } from 'src/components/pages/user';
import Swal from 'sweetalert2';

function DailyNews() {
  const dispatch = useDispatch();
  const { dailyNews } = useSelector((state) => state.news);
  const [loading, setLoading] = useState(false);

  const deleteDailyNews = async (newsId) => {
    await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        await dispatch(updateDailyNews({ newsId, isDaily: 0 }));
        await dispatch(getDailyNews());
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    dispatch(getDailyNews());
  }, []);

  return (
    <Page title="Highlight News">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Daily News {loading ? '(Güncelleniyor...)' : null}
          </Typography>
        </Stack>

        {dailyNews?.length > 0 ? (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, px: 4 }}>
                <Table>
                  <UserListHead
                    headLabel={[
                      { id: 'name', label: 'Name', alignRight: false },
                      { id: '' },
                    ]}
                  />
                  <TableBody>
                    {dailyNews?.map((news) => {
                      return (
                        <TableRow hover key={news?.id} tabIndex={-1}>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar alt={news?.title} src={news?.image} />
                              <Typography variant="subtitle2">
                                {news?.title ?? 'Null'}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <Button
                              onClick={() => deleteDailyNews(news?.id)}
                              color="error"
                            >
                              Sil
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        ) : (
          <Typography variant="subtitle2">Gösterilecek içerik yok</Typography>
        )}
      </Container>
    </Page>
  );
}

export default DailyNews;
