import React, { useEffect, useState } from 'react';
import { Page } from '@components';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import Scrollbar from '@components/shared/Scrollbar';
import { UserListHead } from 'src/components/pages/user';
import { env } from '@env';
import axios from 'axios';
import Swal from 'sweetalert2';

function PopularNews() {
  const [popularNews, setPopularNews] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const getPopularNews = async (page = 0) => {
    await axios
      .get(`${env.API_URL}/admin/getPopularNews?page=${page + 1}`)
      .then((res) => {
        setPopularNews(res.data);
      });
  };

  const handleChangePage = async (event, newPage) => {
    await getPopularNews(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    await getPopularNews(0);
    setPage(0);
  };

  const updatePopularNews = async (newsId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${env.API_URL}/admin/updatePopularNews`, { newsId, status: 0 })
          .then(() => {
            getPopularNews(page);
          });
      }
    });
  };

  useEffect(() => {
    getPopularNews();
  }, []);

  return (
    <Page title="Popular News">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Popular News
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'name', label: 'Name', alignRight: false },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {popularNews?.data?.map((row) => {
                    return (
                      <TableRow hover key={row?.id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={row?.title} src={row?.image} />
                            <Typography variant="subtitle2">
                              {row?.title ?? 'Null'}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            onClick={() => updatePopularNews(row?.id)}
                            color="error"
                          >
                            Sil
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {popularNews?.total ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={popularNews?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}

export default PopularNews;
