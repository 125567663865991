import { createSlice } from '@reduxjs/toolkit';
import { resetUser, setUser } from './user.actions';

const initialState = {
  user: null,
};

const user = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(resetUser.fulfilled, (state) => {
        state.user = null;
      });
  },
});

export default user.reducer;
