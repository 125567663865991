import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../../style/pagination.css';

import {
  Button,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

import { PostCard } from 'src/components/pages/news';
import { Iconify, Page } from '@components';

import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import Pagination from 'react-js-pagination';
import { env } from '@env';
import { getNews } from '@features/news/news.actions';
import { setNews } from '@features/news/news.slice';
import { Search } from 'src/components/pages/news';
import useDebounce from '../../hooks/useDebounce';
import { getCategories } from '@features/categories/categories.actions';

export default function News() {
  const { news, newsLoading } = useSelector((state) => state.news);
  const { categories } = useSelector((state) => state.categories);
  const [activePage, setActivePage] = useState(1);
  const [newsAutocomplete, setNewsAutocomplete] = useState();
  const [activeCategory, setActiveCategory] = useState('gundem');
  const [showHiddenSource, setShowHiddenSource] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debounceSearchText = useDebounce(searchText, 500);

  const dispatch = useDispatch();

  const fetchNews = async (pageNumber = 0, category) => {
    dispatch(getNews({ category, page: pageNumber, showHiddenSource }));

    pageNumber ? setActivePage(pageNumber) : setActivePage(1);
  };

  const handleSearchAutocomplete = async (title) => {
    const news = (await axios.get(`${env.API_URL}/searchNews/${title}`)).data;
    dispatch(setNews(news));

    if (title === undefined) {
      fetchNews(0, activeCategory);
    }
  };

  useEffect(() => {
    if (debounceSearchText) {
      axios
        .get(`${env.API_URL}/searchNews/${debounceSearchText}`)
        .then((res) => {
          setNewsAutocomplete(res?.data);
        });
    }
  }, [debounceSearchText]);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    fetchNews(0, activeCategory);
  }, [showHiddenSource]);

  return (
    <Page title="News">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4">News</Typography>

          <Stack direction="row" gap={2}>
            <Button
              startIcon={
                <Iconify
                  icon="eva:star-outline"
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
              }
              variant="outlined"
              component={RouterLink}
              to="trends"
            >
              Trends
            </Button>

            <Button
              variant="contained"
              component={RouterLink}
              to="add"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add News
            </Button>
          </Stack>
        </Stack>

        <Stack
          mb={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Search
            label="Search News"
            disabled={newsLoading}
            posts={newsAutocomplete ?? news?.data}
            onChangeInput={(e) => setSearchText(e)}
            onChange={(e) => handleSearchAutocomplete(e)}
          />
        </Stack>

        <Stack mb={2} alignItems="flex-start" p={0}>
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={<Switch color="success" />}
            label="Hidden Sources"
            disabled={newsLoading}
            labelPlacement="end"
            onChange={(event, checked) => setShowHiddenSource(checked)}
            color="red"
          />
        </Stack>

        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          overflow="auto"
          gap={2}
        >
          {categories?.map((category) => (
            <Chip
              color="info"
              disabled={newsLoading}
              variant={category?.slug !== activeCategory ? 'outlined' : ''}
              onClick={() => {
                fetchNews(0, category?.slug);
                setActiveCategory(category?.slug);
              }}
              label={category?.name}
            />
          ))}
        </Stack>

        {news?.data?.length > 0 ? (
          <Grid container spacing={3}>
            {news?.data?.map((item, index) => (
              <PostCard
                key={item.id}
                news={item}
                title={item.title}
                url={`${env.FRONT_URL}/news/${item?.slug}`}
                avatar={item?.source?.cdn_image}
                name={item?.source?.name}
                createdAt={item?.date}
                image={item.image}
                index={index}
                showIconNumber={false}
                showInfo={false}
                showActionButtons={true}
              />
            ))}
          </Grid>
        ) : null}

        {news?.per_page && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={news?.per_page}
            totalItemsCount={news?.total}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => fetchNews(pageNumber, activeCategory)}
          />
        )}
      </Container>
    </Page>
  );
}
