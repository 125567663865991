import React, { useEffect, useState } from 'react';
import { CustomModal } from '@components';
import { Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ReactQuill from 'react-quill';
import axios from 'axios';
import { env } from '@env';
import { editNews } from '@features/news/news.actions';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenEditNewsModal } from '@features/news/news.slice';

import './EditNewsModal.css';

function EditNewsModal() {
  const dispatch = useDispatch();
  const { editNewsLoading, newsDetail, openEditNewsModal } = useSelector(
    (state) => state.news
  );

  const [id, setId] = useState();
  const [title, setTitle] = useState();
  const [aiTitle, setAiTitle] = useState('');
  const [spot, setSpot] = useState('');
  const [image, setImage] = useState();
  const [url, setUrl] = useState();
  const [aiTitleDisabled, setAITitleDisabled] = useState(true);
  const [getContentLoading, setGetContentLoading] = useState(false);

  const getContent = () => {
    setGetContentLoading(true);
    axios
      .get(`${env.ROVER_URL}/v1/news/detail/${id}?hasContent=true`)
      .then((res) => {
        setSpot(res?.data?.data?.content_plain);
      })
      .finally(() => {
        setGetContentLoading(false);
      });
  };

  const onEditNews = async () => {
    const res = await dispatch(
      editNews({
        id,
        title,
        aiTitle: aiTitleDisabled ? null : aiTitle,
        spot,
        image,
        url,
      })
    );
    const message = res?.payload?.message;

    if (message === 'URL_ALREADY_EXISTS') {
      toast.error('Böyle bir url zaten mevcut.');
      return;
    }

    toast.success('Your news has been edited.');
  };

  useEffect(() => {
    setId(newsDetail?.id);
    setTitle(newsDetail?.title);
    setAiTitle(newsDetail?.clickbait_reports?.title ?? '');
    setSpot(newsDetail?.spot);
    setImage(newsDetail?.image);
    setUrl(newsDetail?.url);
    setAITitleDisabled(true);
  }, [openEditNewsModal]);

  return (
    <CustomModal
      title="Edit News"
      isOpen={openEditNewsModal}
      onClose={() => dispatch(setOpenEditNewsModal(false))}
    >
      <Stack spacing={3}>
        <div className="edit-modal-header">
          <LoadingButton
            fullWidth
            size="small"
            loading={getContentLoading}
            onClick={() => getContent()}
            variant="contained"
          >
            Get Content
          </LoadingButton>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => setAITitleDisabled(!aiTitleDisabled)}
          >
            {aiTitleDisabled ? 'Edit AI Title' : 'Cancel AI Title'}
          </Button>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => {
              setAITitleDisabled(false);
              setAiTitle(title);
            }}
          >
            Copy Title
          </Button>
        </div>
        <TextField
          name="title"
          label="Title"
          value={title}
          multiline
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          name="aiTitle"
          label="AI Title"
          value={aiTitle}
          multiline
          onChange={(e) => setAiTitle(e.target.value)}
          disabled={aiTitleDisabled}
        />

        <ReactQuill name="spot" theme="snow" value={spot} onChange={setSpot} />
        <TextField
          name="image"
          label="Image"
          value={image}
          multiline
          onChange={(e) => setImage(e.target.value)}
        />
        <TextField
          name="url"
          label="Original Url"
          value={url}
          multiline
          onChange={(e) => setUrl(e.target.value)}
        />
        <input name="id" type="hidden" value={id} />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={onEditNews}
          loading={editNewsLoading}
        >
          Edit
        </LoadingButton>
      </Stack>
    </CustomModal>
  );
}

export default EditNewsModal;
