import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getTopics = createAsyncThunk('topic/getTopics', async () => {
  return (await axios.get(`${env.API_URL}/admin/getTopics`)).data;
});

export const getTopicById = createAsyncThunk(
  'topic/getTopicById',
  async (topicId) => {
    return (
      await axios.get(`${env.API_URL}/admin/getTopicById?topicId=${topicId}`)
    ).data;
  }
);

export const addTopic = createAsyncThunk('topic/addTopic', async (data) => {
  return (await axios.post(`${env.API_URL}/admin/addTopic`, data)).data;
});

export const updateTopic = createAsyncThunk(
  'topic/updateTopic',
  async (data) => {
    return (await axios.post(`${env.API_URL}/admin/updateTopic`, data)).data;
  }
);

export const deleteTopic = createAsyncThunk(
  'topic/deleteTopic',
  async (topicId) => {
    return (
      await axios.post(`${env.API_URL}/admin/deleteTopic?topicId=${topicId}`)
    ).data;
  }
);

export const changeTopicStatus = createAsyncThunk(
  'topic/changeTopicStatus',
  async (topicId) => {
    return (
      await axios.post(
        `${env.API_URL}/admin/changeTopicStatus?topicId=${topicId}`
      )
    ).data;
  }
);
