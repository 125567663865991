import React, { useEffect } from 'react';
import { CustomModal } from '@components';
import { Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { editSource, getSources } from '@features/source/source.actions';
import { setOpenEditSourceModal } from '@features/source/source.slice';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

function EditSourceModal() {
  const dispatch = useDispatch();
  const { openEditSourceModal, editSourceFields, sourcesLoading } = useSelector(
    (state) => state.source
  );

  const formik = useFormik({
    initialValues: {
      id: editSourceFields?.id,
      name: editSourceFields?.name,
      slug: editSourceFields?.slug,
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      dispatch(editSource(formData)).then((res) => {
        if (res?.payload?.message === 'SUCCESS') {
          toast.success('Kaynak güncellendi.');
        }
        dispatch(getSources());
        dispatch(setOpenEditSourceModal(false));
      });
    },
  });

  useEffect(() => {
    formik.setFieldValue('id', editSourceFields?.id);
    formik.setFieldValue('name', editSourceFields?.name);
    formik.setFieldValue('slug', editSourceFields?.slug);
  }, [openEditSourceModal]);
  return (
    <CustomModal
      isOpen={openEditSourceModal}
      onClose={() => dispatch(setOpenEditSourceModal(false))}
    >
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Stack spacing={2}>
          <Stack
            spacing={1}
            border={1}
            borderColor="#f2f2f2"
            borderRadius={2}
            p={2}
            mt={4}
          >
            <label style={{ fontWeight: 'bold' }}>Logo</label>
            <input
              type="file"
              name="image"
              onChange={(event) =>
                formik.setFieldValue('image', event.target.files[0])
              }
            />
            <img
              height={80}
              width={80}
              src={editSourceFields?.image}
              alt="logo"
            />
          </Stack>
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Name"
            name="name"
            value={formik?.values?.name}
            onChange={formik.handleChange}
          />
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Slug"
            name="slug"
            disabled
            value={formik?.values?.slug}
            onChange={formik.handleChange}
          />
          <LoadingButton
            loading={sourcesLoading}
            type="submit"
            variant="contained"
          >
            Güncelle
          </LoadingButton>
        </Stack>
      </form>
    </CustomModal>
  );
}

export default EditSourceModal;
