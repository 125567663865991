import React, { useEffect } from 'react';
import {
  Autocomplete,
  Button,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getTopicById, updateTopic } from '@features/general/general.actions';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { env } from '@env';

function AddNews() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { topicById } = useSelector((state) => state.general);

  const AddNewsSchema = Yup.object().shape({
    title: Yup.string().required('Zorunlu'),
    desc: Yup.string().required('Zorunlu'),
    tags: Yup.array().required('Zorunlu'),
  });

  const formik = useFormik({
    initialValues: {
      image: null,
      title: '',
      desc: '',
      tags: '',
    },
    validationSchema: AddNewsSchema,
    onSubmit: async (values) => {
      const tags = values.tags.join(',');
      const data = { ...values, tags, id: params?.id };

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      dispatch(updateTopic(formData)).then((res) => {
        if (res.payload.message === 'SUCCESS') {
          toast.success('Topic güncellendi.');
          dispatch(getTopicById(params?.id));
        } else {
          toast.error('Beklenmedik bir hata oluştu.');
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getTopicById(params?.id));
    formik.setFieldValue('tags', topicById?.tags?.split(','));
    formik.setFieldValue('title', topicById?.title);
    formik.setFieldValue('desc', topicById?.desc);
  }, []);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h3" gutterBottom>
            Edit <span className="ant-tag-red">{topicById?.title}</span> Topic
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <div>
            <Typography fontWeight="bold" gutterBottom>
              Fotoğraf:
            </Typography>
            <input
              type="file"
              name="image"
              onChange={(event) => {
                formik.setFieldValue('image', event.currentTarget.files[0]);
              }}
            />
            <div style={{ color: 'red', marginTop: 10 }}>
              {formik.touched.image && formik.errors.image}
            </div>
            <img src={topicById?.image} height={100} alt="topic" />
          </div>
          <Divider />
          <TextField
            id="outlined-basic"
            variant="outlined"
            error={formik.touched.title && formik.errors.title}
            helperText={formik.touched.title && formik.errors.title}
            label="Title"
            defaultValue={topicById?.title}
            name="title"
            onChange={formik.handleChange}
          />
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Desc"
            name="desc"
            rows={10}
            multiline
            defaultValue={topicById?.desc}
            onChange={formik.handleChange}
            error={formik.touched.desc && formik.errors.desc}
            helperText={formik.touched.desc && formik.errors.desc}
          />
          <Autocomplete
            multiple
            id="tags-standard"
            freeSolo
            options={[]}
            onChange={(event, value) => formik.setFieldValue('tags', value)}
            defaultValue={topicById?.tags.split(',')}
            renderInput={(params) => (
              <TextField
                {...params}
                name="tags"
                error={formik.touched.tags && formik.errors.tags}
                helperText={formik.touched.tags && formik.errors.tags}
                variant="standard"
                label="Tags"
                placeholder="Tag"
              />
            )}
          />
          <Button type="submit" variant="outlined">
            Update
          </Button>
        </Stack>
      </form>
    </Container>
  );
}

export default AddNews;
