import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
// utils
import { fDate, fToNow } from '@utils/formatTime';
import { fShortenNumber } from '@utils/formatNumber';
//
import SvgIconStyle from '@components/shared/SvgIconStyle';
import Iconify from '@components/shared/Iconify';
import { Label } from '@components';
import React from 'react';
import { useNews } from '@hooks';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 2 / 4)',
});

const TitleStyle = styled('a')({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  gap: 20,
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

export default function NewsCard({
  news,
  image,
  title,
  url,
  view,
  avatar,
  name,
  comment,
  share,
  createdAt,
  index,
  showInfo = true,
  showActionButtons = false,
}) {
  const latestPostLarge = index === 0;
  const latestPost = index === 1 || index === 2;
  const infoBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ml: index === 0 ? 0 : 1.5,
    ...((latestPostLarge || latestPost) && { color: 'grey.500' }),
  };

  const POST_INFO = [
    { number: comment, icon: 'eva:message-circle-fill' },
    { number: view, icon: 'eva:eye-fill' },
    { number: share, icon: 'eva:share-fill' },
  ];

  const {
    updatePopularNews,
    onDeleteNews,
    handleEditNewsModal,
    handleNewsNotificationModal,
    handleUpdateDailyNews,
    handleAddCommentModal,
  } = useNews();

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle>
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              color: 'background.paper',
            }}
          />
          <AvatarStyle alt={name} src={avatar} />

          <CoverImgStyle alt={title} src={image} />
        </CardMediaStyle>

        <CardContent>
          {createdAt ? (
            <Typography
              gutterBottom
              variant="caption"
              sx={{ color: 'text.disabled', display: 'block' }}
            >
              {fDate(createdAt)} <br /> {fToNow(createdAt)} - {name}
            </Typography>
          ) : null}
          <TitleStyle
            href={url}
            target="_blank"
            color="inherit"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
          >
            {title}
          </TitleStyle>
          {showInfo && (
            <InfoStyle>
              {POST_INFO.map((info, index) => (
                <Box key={index} sx={infoBoxStyle}>
                  <Iconify
                    icon={info.icon}
                    sx={{ width: 16, height: 16, mr: 0.5 }}
                  />
                  <Typography variant="caption">
                    {fShortenNumber(info.number)}
                  </Typography>
                </Box>
              ))}
            </InfoStyle>
          )}

          {showActionButtons ? (
            <InfoStyle>
              <Box sx={infoBoxStyle}>
                <Label variant="outlined">
                  <Iconify
                    icon="eva:bar-chart-outline"
                    sx={{ width: 16, height: 16, mr: 0.5 }}
                  />
                  {news?.views}
                </Label>
              </Box>

              <Box
                onClick={() => handleAddCommentModal(news)}
                sx={infoBoxStyle}
              >
                <Iconify
                  icon="eva:message-circle-outline"
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
              </Box>

              <Box
                onClick={() => updatePopularNews(news?.id)}
                sx={infoBoxStyle}
              >
                <Iconify
                  icon="eva:bulb-outline"
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
              </Box>

              <Box
                onClick={() => handleUpdateDailyNews(news?.id)}
                sx={infoBoxStyle}
              >
                <Iconify
                  icon="eva:sun-outline"
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
              </Box>
              <Box
                onClick={() => handleNewsNotificationModal(news)}
                sx={infoBoxStyle}
              >
                <Iconify
                  icon="eva:bell-outline"
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
              </Box>
              <Box onClick={() => handleEditNewsModal(news)} sx={infoBoxStyle}>
                <Iconify
                  icon="eva:edit-outline"
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
              </Box>
              <Box onClick={() => onDeleteNews(news.id)} sx={infoBoxStyle}>
                <Iconify
                  icon="eva:trash-outline"
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
              </Box>
            </InfoStyle>
          ) : null}
        </CardContent>
      </Card>
    </Grid>
  );
}
