import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Link, Typography } from '@mui/material';
// hooks
import { useResponsive } from '@hooks';
// components
import { Page } from '@components';
// sections
import { LoginForm } from '@components/pages/auth/login';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/dashboard/app', { replace: true });
    }
  }, [user]);

  return (
    <Page title="Login">
      <RootStyle>
        {!user && (
          <Container maxWidth="sm">
            <ContentStyle>
              <Typography sx={{ mb: 5 }} variant="h4" gutterBottom>
                Sign in to Popil
              </Typography>

              <LoginForm />

              {!smUp && (
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Don’t have an account?{' '}
                  <Link
                    variant="subtitle2"
                    component={RouterLink}
                    to="/register"
                  >
                    Get started
                  </Link>
                </Typography>
              )}
            </ContentStyle>
          </Container>
        )}
      </RootStyle>
    </Page>
  );
}
