import { createSlice } from '@reduxjs/toolkit';
import { getDailyNews, getNews, deleteNews, editNews } from './news.actions';

const news = createSlice({
  name: 'news',
  initialState: {
    news: {},
    newsLoading: false,
    newsDetail: null,
    dailyNews: null,
    editNewsLoading: false,
    openEditNewsModal: false,
    openNewsNotificationModal: false,
    openAddCommentModal: false,
  },
  reducers: {
    setNews: (state, action) => {
      state.news.data = action.payload;
    },
    setNewsDetail: (state, action) => {
      state.newsDetail = action.payload;
    },
    setOpenEditNewsModal: (state, action) => {
      state.openEditNewsModal = action.payload;
    },
    setOpenNewsNotificationModal: (state, action) => {
      state.openNewsNotificationModal = action.payload;
    },
    setOpenAddCommentModal: (state, action) => {
      state.openAddCommentModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    //getNews
    builder.addCase(getNews.pending, (state) => {
      state.newsLoading = true;
      state.news = {};
    });
    builder.addCase(getNews.fulfilled, (state, action) => {
      state.newsLoading = false;
      state.news = action.payload;
    });
    builder.addCase(getNews.rejected, (state) => {
      state.newsLoading = false;
      state.news = {};
    });

    //deleteNews
    builder.addCase(deleteNews.fulfilled, (state, action) => {
      const newsId = action.meta.arg;
      state.news.data = state?.news?.data?.filter(
        (item) => item?.id !== newsId
      );
    });

    //editNews
    builder.addCase(editNews.pending, (state) => {
      state.editNewsLoading = true;
    });
    builder.addCase(editNews.fulfilled, (state, action) => {
      state.editNewsLoading = false;
      const news = action.meta.arg;

      const newsIndex = state?.news?.data?.findIndex(
        (item) => item?.id === news?.id
      );

      state.news.data[newsIndex] = action.payload;
    });
    builder.addCase(editNews.rejected, (state) => {
      state.editNewsLoading = false;
    });

    //getDailyNews
    builder.addCase(getDailyNews.fulfilled, (state, action) => {
      state.dailyNews = action.payload;
    });
  },
});

export default news.reducer;

export const {
  setNews,
  setNewsDetail,
  setOpenNewsNotificationModal,
  setOpenEditNewsModal,
  setOpenAddCommentModal,
} = news.actions;
